import i18next from "i18next";

// tslint:disable-next-line:max-line-length
export const ACCEPTED_CHARACTERS_FOR_TEXT_INPUT_REGEX = /^[a-zA-ZÑñÁáÂâÃãÀàÇçÉéÊêÍíÓóÔôÕõÚúÜüªº.'-\s]+$/;
export const ACCEPTED_CHARACTERS_FOR_TEXT_INPUT_WITH_AT_REGEX = /^[0-9@a-zA-ZÑñÁáÂâÃãÀàÇçÉéÊêÍíÓóÔôÕõÚúÜüªº.'-\s]+$/;
export const EMAIL_REGEX =
	// tslint:disable-next-line:max-line-length
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const NAME_INPUT_MAX_LENGTH = 32;
export const CUG_GROUP_NAME_MAX_LENGTH = 16;
export const ADDRESS_CHARS_REGEX = /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÂâÃãÀàÇçÊêÔôÕõŐőªº\-.,'\"\s]+$/i;
export const ALPHANUMERIC_REGEX = /^[a-zA-ZÑñÁáÂâÃãÀàÇçÉéÊêÍíÓóÔôÕõÚúÜüªº.'-\s\d]*$/;
export const DOCUMENT_NUMBER_REGEX = /^[a-zA-Z0-9-\s\d]*$/;
export const BANK_ACCOUNT_NUMBER_REGEX = /^([a-zA-Z0-9-])*$/;
export const CITY_NAME_REGEX = /^[a-zA-ZÑñÁáÉéÍíÓóÚúÂâÃãÀàÇçÊêÔôÕõŐő'-\s]+$/;
export const CURRENCY_REGEX = /^[A-Z]+$/;
export const FULL_EMAIL_REGEX =
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const GIFTCARD_CHARS_REGEX = /^[a-z0-9áéíñóúü¿¡ãàâçêíõô!:…․‥\-.;,?'\"\s]+$/i;
export const NO_SPECIAL_CHAR_REGEX = /^[^0-9*_$()=@\/\-%!+"'§`÷.:;,ˆˇ˘°×\?<>~\|{}&Ł€£ß#¿¡\\[\]]*$/;
export const NUMERIC_REGEX = /^[0-9]*$/;
export const NUMERIC_AND_PLUS_REGEX = /^[+0-9]*$/;
export const PASSPORT_REGEX = /^[a-zA-Z0-9\u00C0-\u017F]$/;
export const PHONENUMBER_REGEX = /^[\d\s\+]$/;
export const POSTAL_CODE_REGEX = /^[a-zA-ZÑñÁáÉéÍíÓóÚúÂâÃãÀàÇçÊêÔôÕõŐőªº-\s\d]+$/;
export const PROMO_CODE_REGEX = /^([a-zA-Z0-9\u00C0-\u017F]){1,8}$/;
export const RUT_REGEX = /^[0-9-]+$/;
export const RUC_REGEX = /^[0-9-]+$/;
export const TRAVEL_DOCUMENT_ID_REGEX = /^[a-zA-Z0-9\-.]+$/i;
export const USER_NAME_REGEX = /^[a-zA-ZÑñÁáÂâÃãÀàÇçÉéÊêÍíÓóÔôÕõŐőÚúÜü]+$/;
export const VOUCHER_REGEX = /^[0-9]{17}$/;
export const FREQUENT_FLYER_NUMBER_REGEX = /^[a-zA-Z0-9]$/;
export const NIT_REGEX = /^[\d-]$/;
export const CLOCK_REGEX = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

export const thousandSeparatorRegex = /\B(?=(\d{3})+(?!\d))/g;
export const MIN_PAX_NUMBER_FOR_GROUP_BOOKING = 10;
export const MIN_ADULT_AGE = 18;
export const MIN_TEEN_AGE = 14;
export const FLIGHT_SELECT_URL = "Flight/InternalSelect";
export const BOOKING_RETRIEVE_URL = "Booking/Retrieve";
export const CUG3_LOGIN_URL = "Login/Cug3Login";
export const AA_LOGIN_URL = "Login/AmericanAirlinesLogin";
export const API_STATION_URL = "/api/Stations";
export const API_TIMETABLE_URL = "/api/TimeTable";
export const API_TIMETABLE_URL_FROM_PARAM = "fromStation";
export const API_TIMETABLE_URL_TO_PARAM = "toStation";
export const API_AMERICAN_AIRLINES_REFRESH_TOKEN_URL = "/api/AmericanAirlines/UpdateToken";
export const LOGOUT_INLINE_URL = "/Member/LogoutInline";
export const FARECACHE_NAVITAIRE_SETTINGS_PATH = "/configurations";
export const OPEN_CLASS_NAME = "open";
export const MIN_SCREEN_WIDTH_FOR_TWO_MONTH_CALENDAR = 1024;
export const COMMON_DAYJS_FORMAT = "YYYY-MM-DD";
export const DATEPICKER_RANGE_MODE = "range";
export const DATEPICKER_SINGLE_MODE = "single";
export const DATEPICKER_MAX_DATE = "maxDate";
export const DATEPICKER_MIN_DATE = "minDate";
export const DATEPICKER_DISABLED_DATES = "disable";
export const DATEPICKER_MODE = "mode";
export const CULTURE_US = "en-us";
export const CULTURE_BR = "pt-br";

export const DEFAULT_CALENDAR_LOOKAHEAD_DAYS = 365;

export const COOKIES = {
	PREVIOUS_SEARCH: "JS_PreviousSearch",
};

export const LINKS = {
	DC: "https://jetsmart.com/cl/es/club_de_descuentos",
	AMERICAN_USER_ACCOUNT_REDIRECT: "https://iqa2.qa.aa.com/aadvantage-program/profile/account-summary",
};

// COUNTRY CODES
export const AGENCY_PAYMENT_FOP_CODE = "AG";
export const ARGENTINA_COUNTRY_CODE = "AR";
export const BRASIL_COUNTRY_CODE = "BR";
export const BRITAIN_COUNTRY_CODE = "GB";
export const CHILE_COUNTRY_CODE = "CL";
export const COLOMBIA_COUNTRY_CODE = "CO";
export const CREDIT_SHELL_PAYMENT_FOP_CODE = "CF";
export const ECUADOR_COUNTRY_CODE = "EC";
export const ELAVON_PAYMENT_FOP_CODE = "EV";
export const PARAGUAY_COUNTRY_CODE = "PY";
export const PERU_COUNTRY_CODE = "PE";
export const PERU_TRANSFER_FOP_CODE = "PT";
export const SAFETYPAY_PAYMENT_FOP_CODE = "ST";
export const SPAIN_COUNTRY_CODE = "ES";
export const TRANSBANK_PAYMENT_FOP_CODE = "FT";
export const URUGUAY_COUNTRY_CODE = "UY";
export const USA_COUNTRY_CODE = "US";

export const BRASIL_REAL_CODE = "BRL";
export const ARGENTINIAN_PESO_CODE = "ARS";
export const USA_DOLLAR_CODE = "USD";
export const PERUVIAN_SOL_CODE = "PEN";
export const PERUVIAN_SOL_CODE_ALTERNATIVE = "SOL";

export const GENERAL_CURRENCY_SIGN = "$";
export const BRASIL_REAL_CURRENCY_SIGN = "R$";
export const PERUVIAN_SOL_CURRENCY_SIGN = "S/";

export const ARGENTINIAN_CULTURE_CODE = "es-ar";
export const BOLIVIAN_CULTURE_CODE = "es-bo";
export const BRASILIAN_CULTURE_CODE = "pt-br";
export const BRITISH_CULTURE_CODE = "en-gb";
export const CHILEAN_CULTURE_CODE = "es-cl";
export const COLOMBIAN_CULTURE_CODE = "es-co";
export const COSTA_RICAN_CULTURE_CODE = "es-cr";
export const ECUADORIAN_CULTURE_CODE = "es-ec";
export const GUATEMALAN_CULTURE_CODE = "es-gt";
export const HONDURAN_CULTURE_CODE = "es-hn";
export const MEXICAN_CULTURE_CODE = "es-mx";
export const NICARAGUAN_CULTURE_CODE = "es-ni";
export const PANAMANIAN_CULTURE_CODE = "es-pa";
export const PARAGUAYAN_CULTURE_CODE = "es-py";
export const PERUVIAN_CULTURE_CODE = "es-pe";
export const SALVADORAN_CULTURE_CODE = "es-sv";
export const SPAIN_CULTURE_CODE = "es-es";
export const URUGUAYAN_CULTURE_CODE = "es-uy";
export const USA_CULTURE_CODE = "en-us";
export const VENEZUELAN_CULTURE_CODE = "es-ve";

export const COUNTRY_MAP = new Map<string, string>([
	[ARGENTINIAN_CULTURE_CODE, i18next.t("Argentina")],
	[BRASILIAN_CULTURE_CODE, i18next.t("Brasil")],
	[BRITISH_CULTURE_CODE, i18next.t("Others (EN)")],
	[CHILEAN_CULTURE_CODE, i18next.t("Chile")],
	[COLOMBIAN_CULTURE_CODE, i18next.t("Colombia")],
	[ECUADORIAN_CULTURE_CODE, i18next.t("Ecuador")],
	[PARAGUAYAN_CULTURE_CODE, i18next.t("Paraguay")],
	[PERUVIAN_CULTURE_CODE, i18next.t("Perú")],
	[SPAIN_CULTURE_CODE, i18next.t("Otro (ES)")],
	[URUGUAYAN_CULTURE_CODE, i18next.t("Uruguay")],
	[USA_CULTURE_CODE, i18next.t("English")],
]);

export const WEB_ANONYMOUS_ROLE_CODE = "WWWA";
export const AMERICAN_AIRLINES_USER_ROLE = "WWWL";

export const PROMO_CODE_URL_KEY = "pc";

export const CULTURES_WITH_SUNDAY_AS_START_OF_WEEK = ["en-us", "pt-br"];
export const CULTURES_WITH_DECIMAL_DOT = [USA_CULTURE_CODE, PERUVIAN_CULTURE_CODE];

export const OUTBOUND = 0;
export const INBOUND = 1;

interface WidgetUrls {
	carUrl: string;
	transferUrl: string;
}

export const WIDGETS = new Map<string, WidgetUrls>([
	[
		USA_CULTURE_CODE,
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=en&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=SBEN",
			transferUrl: "https://cars.cartrawler.com/jetsmart/en/search-gt?c=ie#/search?client=874601",
		},
	],
	[
		BRASILIAN_CULTURE_CODE,
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=br&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=hp_panel&adcamp=standalone",
			transferUrl: "https://cars.cartrawler.com/jetsmart/pt/search-gt?c=ie#/search?client=874601&lang=PT",
		},
	],
	[
		ARGENTINIAN_CULTURE_CODE,
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=es&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=SBAR",
			transferUrl: "",
		},
	],
	[
		PERUVIAN_CULTURE_CODE,
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=es&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=SBPE",
			transferUrl: "",
		},
	],
	[
		"default",
		{
			carUrl: "https://www.rentalcars.com/partners/integrations/stand-alone-app/?preflang=es&fts=true&enableReturnCheckbox=true&css=true&affiliateCode=jetsmart&adplat=SBCH",
			transferUrl: "https://cars.cartrawler.com/jetsmart/es/search-gt?c=ie#/search?client=874601",
		},
	],
]);

export type InputType =
	| "accepted-text-chars"
	| "accepted-text-chars-with-at"
	| "address"
	| "alphanumeric"
	| "bank-account-number"
	| "city"
	| "currency"
	| "document-number"
	| "e-mail"
	| "giftcard"
	| "nit"
	| "no-special-character"
	| "numeric"
	| "numeric-and-plus"
	| "passport"
	| "possible-phonenumber-chars"
	| "postal-code"
	| "promo-code"
	| "rut"
	| "ruc"
	| "travel-document-id"
	| "user-name"
	| "voucher"
	| "frequentFlyerNumber";

export const RegExpForInputTypes = new Map<InputType, RegExp>([
	["accepted-text-chars", ACCEPTED_CHARACTERS_FOR_TEXT_INPUT_REGEX],
	["accepted-text-chars-with-at", ACCEPTED_CHARACTERS_FOR_TEXT_INPUT_WITH_AT_REGEX],
	["address", ADDRESS_CHARS_REGEX],
	["alphanumeric", ALPHANUMERIC_REGEX],
	["bank-account-number", BANK_ACCOUNT_NUMBER_REGEX],
	["city", CITY_NAME_REGEX],
	["currency", CURRENCY_REGEX],
	["document-number", DOCUMENT_NUMBER_REGEX],
	["e-mail", EMAIL_REGEX],
	["giftcard", GIFTCARD_CHARS_REGEX],
	["nit", NIT_REGEX],
	["no-special-character", NO_SPECIAL_CHAR_REGEX],
	["numeric", NUMERIC_REGEX],
	["numeric-and-plus", NUMERIC_AND_PLUS_REGEX],
	["passport", PASSPORT_REGEX],
	["possible-phonenumber-chars", PHONENUMBER_REGEX],
	["postal-code", POSTAL_CODE_REGEX],
	["promo-code", PROMO_CODE_REGEX],
	["rut", RUT_REGEX],
	["ruc", RUC_REGEX],
	["travel-document-id", TRAVEL_DOCUMENT_ID_REGEX],
	["user-name", USER_NAME_REGEX],
	["voucher", VOUCHER_REGEX],
	["frequentFlyerNumber", FREQUENT_FLYER_NUMBER_REGEX],
]);

export const AMERICAN_AIRLINES_MEMBER_TIERS: Record<string, { name: string }> = {
    R: { name: "AAdvantage{{reg}} Member" },
    G: { name: "AAdvantage Gold{{reg}}" },
    P: { name: "AAdvantage Platinum{{reg}}" },
    T: { name: "AAdvantage Platinum Pro{{reg}}" },
    E: { name: "AAdvantage Executive Platinum{{reg}}" },
    C: { name: "Concierge{{reg}} Key" },
};
