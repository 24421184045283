import { CLASS_NAMES } from "./ClassNames";
import { EMAIL_REGEX } from "./constants";

type InputField = HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement;

export function validate(container: Element, captcha?: [{ id: number; container: HTMLDivElement }]) {
	clearErrors(container, captcha);
	let valid = true;

	if (!container) {
		return true;
	}

	const inputs = getAllInputs(container);
	const required = inputs.filter((i) => isInputRequired(i) && isInputVisible(i) && isInputEnabled(i));

	const formResources = window.formResources;
	const errors: string[] = [];

	required.forEach((e) => {
		const parent = e.closest("div") as HTMLDivElement;
		e.value = e.value ? e.value.trim() : "";

		if (isRequiredInputMissing(e)) {
			valid = false;
			let errormsg = formResources.fieldRequired;

			e.classList.add(CLASS_NAMES.error);

			if (isEmailInvalidFormat(e)) {
				errormsg = formResources.emailNotValid;
			}

			addErrorMsg(parent, errormsg);
			errors.push(errormsg);
		}
	});

	return valid;
}

function isRequiredInputMissing(input: InputField): boolean {
	if (input.type === "radio") {
		const name = input.getAttribute("name");
		return document.querySelectorAll(`[name='${name}']:checked`).length !== 1;
	} else {
		return input.hasAttribute("required") && !input.value;
	}
}

function isEmailInvalidFormat(input: InputField): boolean {
	return input.value !== undefined && input.value !== "" && input.type === "email" && !EMAIL_REGEX.test(input.value);
}

function isInputEnabled(input: InputField): boolean {
	return input.disabled === false;
}

function isInputVisible(input: InputField): any {
	return !input.classList.contains("hidden") && (input.type === "hidden" || input.offsetHeight > 0);
}

function isInputRequired(input: InputField) {
	return input.hasAttribute("required");
}

export function getAllInputs(container: Element): InputField[] {
	return Array.from(container.querySelectorAll("input, select, textarea")) as InputField[];
}

export function clearErrors(container: Element, captcha?: [{ id: number; container: HTMLDivElement }]) {
	if (container) {
		const inputs = getAllInputs(container);
		const required = inputs.filter((i) => isInputRequired(i) && isInputVisible(i));
		required.forEach((e) => {
			const parent = e.closest("div") as HTMLDivElement;
			e.classList.remove(CLASS_NAMES.error);
			removeErrorMsg(parent);
		});
	}
	if (captcha) {
		captcha.forEach((c) => {
			c.container.classList.remove(CLASS_NAMES.error);
			removeErrorMsg(c.container);
		});
	}
}

function addErrorMsg(parent: HTMLElement, message: string) {
	if (parent.querySelector(".field-error") === null) {
		const errormsg = document.createElement("span");
		errormsg.classList.add("field-error");
		errormsg.innerText = message;
		parent.appendChild(errormsg);
	}
}

function removeErrorMsg(parent: HTMLElement) {
	const errormsg = parent.querySelector(".field-error");
	if (errormsg) {
		errormsg.remove();
	}
}
